import { alpha, lighten } from "@material-ui/core";
import { colors, createTheme } from "@mui/material";
import { deDE as coreDeDE } from '@mui/material/locale';
import { HeaderLayout, ThemeCustomizing } from "../types/types";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export const RHLD_THEME_V2 = () => {
    const customizing = {
        headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
        colors: {
          background: "#ffffff",
          font: "#373a41",
          primary: "#006AB3",
          primaryContrast: "#ffffff",
          secondary: "#006AB3",
          secondaryContrast: "#ffffff",
          info: "#0288d1",
          infoLight: "#03a9f4",
          infoContrast: "#ffffff",
          headerBackgroundColor: "#ffffff",
          headerPrimaryColor: '#1976d2',
          footerBackgroundColor: "#f3f3f3",
          footerPrimaryColor: "#373a41",
          trustElementBackgroundColor: '#f3f3f3',
        },
        font: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          headlineFontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        title: 'RheinLand LinkedApp',
        logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/rheinland.svg",
        impressumSrc: 'https://www.rheinland-versicherungen.de/impressum/',
        agbSrc: 'https://www.rheinland-versicherungen.de/nutzungsbedingungen/digitales-postfach',
        datenschutzSrc: 'https://legacy.rheinland-versicherungen.de/datenschutz/digitales-postfach',
        copyright: 'RheinLand Versicherungs AG',
        homepage: 'https://www.rheinland-versicherungen.de',
        homepageTitle: 'RheinLand Versicherungen',
        // kontaktdatenAendern: 'https://meinedaten.rheinland-versicherungen.de/#ihreDaten',
        faq: 'https://www.rheinland-versicherungen.de/service/digitales-postfach#faq',
        serviceTel: '02131 290 0',
        serviceEmail: 'Digitales-Postfach-Support@rheinland-versicherungen.de',
  
      } as ThemeCustomizing;
    const breakpoints = createBreakpoints({})
    return createTheme({
        typography: {
          body1: {
            fontSize: customizing?.font?.fontSize || 15,
          },
          htmlFontSize: customizing?.font?.fontSize || 15,
          h1: {
            fontFamily: '"Noto", serif',
            fontWeight: 400,
            fontSize: '72px',
            lineHeight: '115%',
            color: colors.grey[900],
            [breakpoints.down('md')]: {
              fontSize: '38px'
            }
          },
          h2: {
            fontFamily: '"Noto", serif',
            fontWeight: 400,
            fontSize: '60px',      
            lineHeight: '115%',
            color: colors.grey[900],
            [breakpoints.down('md')]: {
              fontSize: '30px'
            }
          },
          h3: {
            fontFamily: '"Noto", serif',
            fontWeight: 400,
            fontSize: '32px',      
            lineHeight: '115%',
            color: colors.grey[900],
            [breakpoints.down('md')]: {
                fontSize: '22px'        
            }
          },
          fontFamily: '"Roboto", sans-serif',
          fontWeightMedium: customizing?.font?.fontWeightMedium || 300,
          fontWeightLight: customizing?.font?.fontWeightLight || 100,
          fontWeightBold: customizing?.font?.fontWeightBold || 700,
          fontWeightRegular: customizing?.font?.fontWeightRegular || 300,
          fontSize: customizing?.font?.fontSize || 15,
        },
        palette: {
          primary: {
            main: customizing?.colors?.primary || '#009767',
            //light: '#4444',
            contrastText: customizing?.colors?.primaryContrast || '#4444',
          },
          secondary: {
            main: customizing?.colors?.secondary || '#0069b4',
            //light: '#4444',
            contrastText: customizing?.colors?.secondaryContrast || '#4444',
          },
          info: {
            main: customizing?.colors?.info || '#ffffff',
            light: customizing?.colors?.infoLight || '#ffffff',
            contrastText: customizing?.colors?.infoContrast || '#0063ba',
          },
          footer: {
            main: customizing?.colors?.footerBackgroundColor || '#0069b4',
            //light: '#4444',
            contrastText: customizing?.colors?.footerPrimaryColor || '#4444',
          },
          header: {
            main: customizing?.colors?.headerBackgroundColor || '#0069b4',
            //light: '#4444',
            contrastText: customizing?.colors?.headerPrimaryColor || '#4444',
          },
        },
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                '&.la': {
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                }
              }
            },
            defaultProps: {
              className: 'la'
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                zIndex: 1,
                fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                '&.la': {
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                }
              }
            },
            defaultProps: {
              className: 'la'
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
              }
            }
          },
          MuiCircularProgress: {
            styleOverrides: {
              root: {
                color: customizing?.colors?.primaryContrast || '#ffffff',
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: customizing?.colors?.primary || '#0069b4',
                  color: customizing?.colors?.primaryContrast || '#ffffff',
                  "@media (hover: none)": {
                    backgroundColor: customizing?.colors?.primary || '#0069b4',
                    color: customizing?.colors?.primaryContrast || '#ffffff',
                  },
                },
                '&.button-inline' :{
                  color: customizing?.colors?.primary,
                  border:'1px solid',
                  
                },
                lineHeight: 'initial',
                primary: customizing?.colors?.secondaryContrast || '#ffffff',
                '&.la': {
                    borderRadius: '10px',
                  background: customizing?.colors?.secondary || '#009767',
                  color: customizing?.colors?.secondaryContrast || '#ffffff',
                  padding: '0 30px',
                  '&:hover': {
                    backgroundColor: customizing?.colors?.primary || '#0069b4',
                    color: customizing?.colors?.primaryContrast || '#ffffff',
                    "@media (hover: none)": {
                      backgroundColor: customizing?.colors?.primary || '#0069b4',
                      color: customizing?.colors?.primaryContrast || '#ffffff',
                    },
                  },
                }
              },
              text: {
                '&.MuiButton-textPrimary': {
                  background: customizing?.colors?.secondary || '#009767',
                  color: customizing?.colors?.secondaryContrast || '#ffffff',
                },
                '&.MuiButton-textPrimary.button-wizard-back': {
                  background: customizing?.colors?.background || '#009767',
                  color: customizing?.colors?.secondaryContrast || '#ffffff',
                },
                '&.MuiButton-textPrimary:hover': {
                  backgroundColor: customizing?.colors?.primary || '#0069b4',
                  color: customizing?.colors?.primaryContrast || '#ffffff',
                },
                '&.MuiButton-textPrimary.Mui-disabled': {
                  color: alpha(customizing?.colors?.secondaryContrast || '#ffffff', 0.5),
                  background: lighten(alpha(customizing?.colors?.secondary || '#009767', 0.5), 0.5),
                },
                // Some CSS
                //background: 'linear-gradient(45deg, #0069b4 30%, #009767 90%)',
                background: customizing?.colors?.secondary || '#009767',
                borderRadius: '10px',
                border: 0,
                color: customizing?.colors?.secondaryContrast || '#ffffff',
                primary: customizing?.colors?.secondaryContrast || '#ffffff',
                height: 48,
                padding: '0 30px',
    
              },
              ...customizing?.MuiButton?.styleOverrides,
            },
            defaultProps: {
              className: 'la'
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontWeight: "300",
                lineHeight: "1.5",
                color: "black",
                backgroundColor: "#ffffff",
                boxShadow: "rgba(0, 0, 0, 0.15) 2px 3px 3px",
                position: "absolute",
                top: "-15px",
                right: "-7px",
                minWidth: "450px",
                borderTopRightRadius: "0",
    
              },
              arrow: {
                "&:before": {
                  //border: "1px solid #E6E8ED"
    
                },
                //width: "24px",
                //height: "17px",
                color: "#ffffff",
                //color: "red",
                marginLeft: "218px",
                top: "40px",
    
              },
    
    
            },
          },

          MuiInputBase: {
            styleOverrides: {
                root: {
                    borderColor: 'blue',
                    "& > fiedset": {
                        borderColor: 'blue'
                    }
                }
            }
          },
    
    
          MuiCssBaseline: {
            styleOverrides: {
              html: {
                color: customizing?.colors?.font || '#181a1d',
                fontSize: '13pt',
                fontWeight: '300',
                fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
                background: customizing?.colors?.background || '#fafafa',
              },
              body: {
                color: customizing?.colors?.font || '#181a1d',
                fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
                background: customizing?.colors?.background || '#fafafa',
              },
              h1: {
                fontFamily: customizing?.font?.headlineFontFamily || 'Roboto, Helvetica, Arial, sans-serif',
              },
              ...customizing?.font?.styleOverrides ?? {},
              // Fix für Unschönheit im Mui-Datepicker. Kann evtl. mit neuer MUI-Version raus.
              ".MuiPickersDatePickerRoot-toolbar .MuiPickersToolbarButton-toolbarBtn": {
                backgroundColor: 'inherit',
                boxShadow: 'none'
              },
              // Fixes für die gemischte Verwendung des DatePickers mit alter JSS-Engine und Emotion aus MaterialUI 5.
              // Diese Hacks können verschwinden, sobald der offizielle DatePicker in V5 integriert wurde.
              ".MuiFormControl-root": {
                ".MuiFormLabel-root, .MuiInputLabel-root": {
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                  pointerEvents: 'none',
                },
                "&.datepicker .MuiFormLabel-root.MuiInputLabel-shrink": {
                  transform: 'translate(12px, 26px) scale(0.75)'
                },
                "&.datepicker .MuiInputLabel-formControl": {
                  zIndex: 1,
                  transform: 'translate(12px, 36px) scale(1)',
                  pointerEvents: 'none',
                },
                "&.datepicker .MuiInputBase-root.MuiInput-root.MuiInput-underline:hover": {
                  background: 'rgba(0, 0, 0, 0.09)'
                },
                "&.datepicker .MuiInputBase-root.MuiInput-root": {
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                },
                ".MuiInputBase-root": {
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                  background: '#ffffff',
                  backgroundColor: '#ffffff3b',
                },
                ".MuiInputBase-root input": {
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                  paddingTop: 25,
                  paddingRight: 12,
                  paddingBottom: 8,
                  paddingLeft: 12,
                },
                ".MuiInputBase-root.select": {
                  lineHeight: '2.385rem',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                },
                "&.select .MuiSelect-select.MuiInput-input.MuiInputBase-input": {
                  paddingLeft: '8px',
                  paddingTop: '14px'
                },
                "&.select label.MuiInputLabel-root": {
                  lineHeight: '2.385rem',
                  transform: 'translate(12px, 20px) scale(1)'
                },
                "&.select label.MuiInputLabel-root.MuiInputLabel-shrink": {
                  transform: 'translate(12px, 14px) scale(0.75)',
                },
                ".MuiFormHelperText-root": {
                  color: '#00FF00',
                  fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
                },
                ".MuiInput-underline": {
                  "&::before": {
                    borderBottom: '1px solid',
                    borderBottomColor: customizing?.colors?.primary || '#ffffff',
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: '2px solid',
                    borderBottomColor: customizing?.colors?.primary || '#ffffff',
                  },
                  "&::after": {
                    borderBottom: '2px solid',
                    borderBottomColor: customizing?.colors?.primary || '#ffffff',
                  }
                },
              },
              ".MuiPickersToolbar-toolbar": {
                backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
                background: customizing?.colors?.background || '#fafafa',
              },
              '.MuiStepper-root': {
                backgroundColor: 'transparent !important'
              },
              '.MuiStepContent-root': {
                color: customizing?.colors?.primary || '#ffffff'
              },
              '.MuiStepLabel-label': {
                '&.MuiStepLabel-completed': {
                  color: `${customizing?.colors?.primary} !important`
                },
                '&.MuiStepLabel-active': {
                  color: `${customizing?.colors?.primary} !important`
                },
              },
              '.MuiStepIcon-root': {
                '&.MuiStepIcon-completed': {
                  color: `${customizing?.colors?.primary} !important`
                },
                '&.MuiStepIcon-active': {
                  color: `${customizing?.colors?.primary} !important`
                },
              },
              '.answer-element-heading-info': {
                fontSize: '1.1rem',
                fontWeight: '600'
              },
    
              '&.textlinkInsteadOfButton': {
                textDecoration: 'underline',
                cursor: 'pointer',
                color: `${customizing?.colors?.primary} !important`
              },
              ...customizing?.baseline?.styleOverrides ?? {},
            },
          }
        },
      }, coreDeDE)
};